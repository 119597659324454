const API_URLS = {
  // development: "http://localhost:3700",
  // production : "http://localhost:3700"

  production: "https://server.gdest.in",


  //production: "https://3700-idx-project-1720162691714.cluster-3g4scxt2njdd6uovkqyfcabgo6.cloudworkstations.dev",

  //production: "https://ty376c-3700.csb.app",

};

export default API_URLS;
